.logo {                                     /*  SignIn page css styles */
  widows: 6vh;
  height: 6vh;
}

.image {
  width: 80vh;
  height:64vh;
  background-repeat: no-repeat ;
  background-size: cover ;
  background-position: center ;
}

.headerContainer {
  margin-top:10px !important;
  padding-bottom: 0px !important;
}

.headerBtn {
  text-transform: inherit !important;
  color: #428bca !important;
  padding: 10px 15px !important;
  width: fit-content !important;
  border: none !important;
}
.menuListHeader{
  min-width: 210px;
}
.menuListHeader:focus{
  outline: none !important;
}

.pointer{
  cursor: pointer;
}

.errorStyle {
  color: red;
  margin-bottom: 5px;
}

.listButton{
    margin-top: 8px !important;
    height: 40px;
}

.containerPadding {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.error {                                           /* Error style in Common */
  color: red;
}

.appBar {                                          /* AppBar css styles */
  width: 100%;
}

.appBarShift {
  width: calc(100% - 240px) !important;
  margin-left: 240px;
}

.logoWhite {
  height: 64px;
  width: 135px;
}

.search {
  margin-left: 10px;
  flex: 1;
}

.content {
  width: 100%;
  padding-top: 100px;
  margin-bottom: 10px;
}

.container {
  position: relative;
}

.buttonShadow {
  text-transform: capitalize !important;
  box-shadow: none !important;
}

.suggestionsContainerOpen {                     /* Registration page css */
  position: absolute;
  z-index: 998;
  left: 0;
  right: 0;
}

.suggestion {
  display: block;
}

.suggestionsList {
  margin: 0;    
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
}

.buttonText {
  text-transform: capitalize !important;
}

.listSuggestions {
  max-height: 225px;
  max-width: 670px;
  overflow: auto;
}

.branchDetailsStyle {
  font-size: 15px;
  font-weight: bold !important;
}

.orgListStyle{
  min-width: 670px;
}

.listhover :hover{
  background-color: #E8E9EB;
}

.listStyle {                                    /* Drawer styles*/
  padding-left: 16px !important;
  font-size: 15px;
}

.drawer {
  width: 240px;
}

.headerBtn1 {
  text-transform: inherit !important;
  color: #428bca !important;
  padding: 10px 20px !important;
  width: fit-content !important;
  border: none !important;
  font-size: 16px !important;
  margin: 10px 0px 0px 0px !important;
  width: 100% !important;
}

.type {
  padding: 10px !important;
  background-color: none;
}

.drawerMainList {
  font-size: 15px !important;
}

.paddingDrawer {
  padding-left: 3px;
}

.drawerMargin {
  margin-bottom: 40px;
}

.textAlign {                                    /* Concurrent Login Styles */
  padding: 5px;
}

.payableAmount {                                /* Billing page Styles */
  font-weight: bolder !important;
}

.footer {
  /* width: calc(100% - 100px) !important; */
  margin-bottom: 100px;
}

.expansionPanelColor {
  background-color: #eff0f1 !important;
}

.testSampleCircle {     
  display: inline-flex;                        /* Pending Accession Styles */
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 0.5px solid rgb(136, 129, 129);
}

.buttonAccession {
  padding: 6px 12px !important;
  text-transform: inherit !important;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing: normal !important;
}

.collectButtonPadding {
  padding-left: 13px;
}

.collectAllButtonPadding {
  padding-left: 6px;
}

.departmentSelect {
  min-width: 200px;
}

.datePickerButton {
  height: 40px;
  color: black !important;
}

.dividerPadding {                                /* Report Settings page Styles*/  
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.label {
  font-size: 15px !important;
  font-weight: bold !important;
}

.submitButton {
  padding: 6px 35px !important;
}

.fontColor {
  color: #000000;
  opacity: 0.5;
}

.uploadHeader{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ececec;
}

.uploadText{
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ececec;
}
.paperHeight {
  min-height: 440px;
  max-height: 440px;
  overflow: scroll;
}

.dialogHeight {
  min-height: 590px;
}

.datePicker {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.buttonPadding {
  padding: 0px !important;
}

.fontSizeIcon {
  font-size: 6.188rem !important;
}

.radioHelperText {                              /* Outsource Management */
  padding-left: 31px;
}

.buttonColor {                                    /* User Management Styles */
  background-color: #FF0000 !important;
  color: white !important;
}

.dialogWidth {                                    /* Profile Update */
  min-height: 710px;
}

.fontSizeProfileIcon {
  font-size: 11.188rem !important;
}

.buttonLineHeight {
  line-height: 10px !important;
}

.heightFix{
  max-height: 120px;
  overflow-y: scroll;
}

.updatePasswordHeight {
  min-height: 589px;
  overflow: scroll;
}

.paperClass {                                         /*List Management Styles*/
  max-height: 225px;
  min-width: 553px;
  overflow: auto;
}

.box {
  border-radius: 5px;
  border: 1px solid black;
}

.fontSmallSize {
  font-size: 12px;
  padding: 2px;
}

.textColor {
  color: white;
}

.backgroundWhite {
  background-color: white;
}

.backgroundGrey {
  background-color: gray;
}

.backgroundLightGrey {
  background-color: #D3D3D3;
}

.backgroundGreen {
  background-color: green;
}

.backgroundBlue {
  background-color: blue;
}

.tableCell {
  border-bottom: unset !important;
  padding: 5px !important;
}

.backArrowFontSize {
  font-size: 0.78rem !important;
}

.flexBasisRoot {
  flex-basis: 0px !important;
}

.backgroundPurple {
  background-color: #aa00ff;
}

.backgroundColor {
  background-color: white;
}

.searchClass {                                         /*List Management Styles*/
  max-height: 225px;
  min-width: 376px;
  overflow: auto;
}

.addTestList {
  max-height: 225px;
  min-width: 666px;
  overflow: auto;
}

.whiteColor {
  color: white;
}

.warningStyles {
  background-color: #fcf8e3;
  border: 1px solid #fada5e;
  border-radius: 5px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  color: #fff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
}

.dismissedTag{
  padding: 3px 5px;
  background-color: #FF0000;
  color: #ffffff;
  border-radius: 12px;
  width: fit-content;
  font-size: 11px;;
  display: inline-block;
  margin-left: 5px;
}

.billId{
  font-weight: bold;
}

.rejectedTestNames{
  font-size: 12px;
}

.authBox {                                /*  Center Management */
  padding: 3px;
  background-color: #c0dd5b;
  border: 1px solid #888888;
  border-radius: 3px;
  font-weight: 300;
}

.redWarningStyles {
  background-color: #f2dede;
  border-color: #ebccd1 !important;
  color: #a94442;
  border: 1px solid transparent;
  border-radius: 5px;
}

.buttonStyle {                                           /*Bill Settlements*/
  text-transform: inherit !important;
  color: #428bca !important;
  padding: 0px 0px !important;
  width: fit-content !important;
  border: none !important;
}

.cursorStyle {
  cursor: pointer;
}

.tablePadding {
  padding: 0px 10px 0px 10px !important;
  border-bottom: none !important;
}

.menuPadding {
  padding: 0px 10px 3px 10px !important;
}

.backgroundOrange {
  background-color: #fd6a02;
}

.borderColor {
  border: 1px solid #e3e3e3;
}

.padding{
  padding: 20px !important;
}

.fontSize {
  font-size: 18px !important;
}

.errorMessage {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.backgroundBrown {
  background-color: #d9534f;
}

.fontSizeArrowIcon {                                /* Sample Management */
  font-size: 0.90rem !important;
}

.sampleContainerCircle {     
  display: inline-flex;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 0.5px solid rgb(136, 129, 129);
}

.menuListWidth {                                  /* Operation Maganement */
  min-width: 230px;
}

.buttonKeyStyles {
  padding: 2px 4px;
  font-size: 90%;
  color: black;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.25);
}

.backgroundLightPurple {
  background-color: #f9f9f9;
}

.paddingButton {                                     /* Batch Management */
  padding: 2px 10px !important;
}

.marginTop {
  margin-top: 2px;
}

.bar {
  height: 15px !important;
  border-radius: 8px !important;
  background-color: #5cb85c !important;
}